<!-- <mat-divider class="pb-3"></mat-divider> -->
<ng-scrollbar id="vertical-menu" pointerEventsMethod="scrollbar">
  <app-vertical-menu
    [menuItems]="menuItems"
    [menuParentId]="0"
  ></app-vertical-menu>

  @if (this.settings.menuType !== "mini") {
    <mat-divider class="mt-3"></mat-divider>
  <div class="py-4">
    <app-sub-menu-items [items]="mobileMenuItem"></app-sub-menu-items>
  </div>
  }
</ng-scrollbar>
<div class="w-100 d-flex justify-content-center align-items-center mt-2">
  <div
    class="user-logout-section d-flex align-items-center"
    [ngClass]="{
      'justify-content-center': this.settings.menuType == 'mini',
      'justify-content-between': this.settings.menuType !== 'mini',
      'width-fit-content':this.settings.menuType == 'mini'
    }"
  >
    @if (this.settings.menuType !== "mini") {

    <div class="text">
      <p class="user-name">
        {{ selectedAccount?.firstName }} {{ selectedAccount?.lastName }}
      </p>
      <p class="logout-label">log out</p>
    </div>
    }
    <img
      src="assets/v2-asset/log-out.svg"
      class="cursor-pointer"
      (click)="logout()"
      alt=""
    />
  </div>
</div>
