import { CommonModule } from "@angular/common";
import { Component, HostListener, Input, input } from "@angular/core";
export interface ITileInfo {
  title: string;
  icon: string;
  number?: number;
  description: string;
  mobileDescription?: string;
  flag:string
  route:string
}
@Component({
  selector: "app-tile",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./tile.component.html",
  styleUrl: "./tile.component.scss",
})
export class TileComponent {
  @Input({required:true})tileInfo:ITileInfo;
  isMobileWidth:boolean = false;
  ngOnInit(){
    if(window.innerWidth <= 768){
      this.isMobileWidth = true;
    }else{
      this.isMobileWidth = false;
    }
  }


  @HostListener("window:resize") onWindowResize(): void {
    if(window.innerWidth <= 768){
      this.isMobileWidth = true;
    }else{
      this.isMobileWidth = false;
    }
  }
}
