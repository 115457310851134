import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ProfileService } from "src/app/features/accounts/services/profile/profile-services.service";
import { CaseDetailsStateService } from "src/app/features/cases/services/case-details-state.service";
import { QuoteCreationService } from "src/app/features/quotes/services/quote-creation.service";
import { Document } from "src/app/models/case-details-model";
import { Capacitor } from '@capacitor/core';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { ToastrService } from "ngx-toastr";
import { LoadingService } from "src/app/shared/services/loading/loading.service";



@Component({
  selector: "app-attachmentsd-dialog",
  templateUrl: "./attachments-dialog.component.html",
  styleUrls: ["./attachments-dialog.component.scss"],
})
export class AttachmentsDialogComponent {
  documents: Document[];
  accountId: string;
  remainingDocs:Document[];

  constructor(
    public dialogRef: MatDialogRef<AttachmentsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { documents: Document[] },
    public quoteCreationService: QuoteCreationService,
    public caseDetailsService:CaseDetailsStateService,
    public profileService: ProfileService,
    private toastr: ToastrService,
    private loadingService: LoadingService,
  ) {
    this.accountId = this.profileService.selectedAccount().accountId ?? "";
    this.documents = data.documents;

    
    this.documents.forEach((doc) => {
      const parts = doc.title.split(".");
      doc.extension = parts.length > 1 ? parts[parts.length - 1] : "";
    });
    
   
  }
 
  onCancel(): void {
    // Logic to handle Cancel
    this.dialogRef.close();
  }

  truncateFileName(name: string, limit: number): string {
    if (name.length > limit) {
      return name.substring(0, limit) + "...";
    }
    return name;
  }

  getImageByExtension(extension: string | undefined): string {
   
    switch (extension) {
      case "pdf":
        return "../../../../../assets/v2-asset/pdf-file.svg";
      case "png":
        return "../../../assets/img/icons/png.svg";
      case "jpg":
        return "../../../../../assets/v2-asset/jpg-file.svg";
      case "jpeg":
        return "../../../../../assets/v2-asset/jpg-file.svg";
      case "doc":
        return "../../../assets/v2-asset/doc.svg";
      case "docx":
        return "../../../assets/img/icons/docx.svg";
      case 'xls':
        return '../../../assets/v2-asset/xls.svg'
      default:
        return "../../../assets/img/icons/doc.svg";
    }
  }

  async downloadFile(documentId: string, documentName: string, documentExtension: string | undefined) {
    const accountId = this.accountId;

    this.caseDetailsService.downloadFile(accountId, documentId).subscribe(async blob => {
      if (blob) {
        // Remove the extension from the documentName if it already has one
        const cleanDocumentName = documentName.replace(/\.[^/.]+$/, "");
        const filename = `${cleanDocumentName}.${documentExtension}`;

        if (Capacitor.isNativePlatform()) {
          this.loadingService.startLoading(true, 'Downloading file...', true);
           // Save file using Capacitor Filesystem API for mobile
          const base64Data = await this.convertBlobToBase64(blob);
            await Filesystem.writeFile({
            path: filename,
            data: base64Data,
            directory: Directory.Documents,
          });
          this.loadingService.startLoading(false);
          this.toastr.success(`File ${filename} saved to Documents directory.`);

          
        } else {
          // Web download
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);  // Append anchor to body
          a.click();
          document.body.removeChild(a);  // Remove anchor from body
          window.URL.revokeObjectURL(url);  // Revoke the object URL
        }
      } else {
        console.error('Failed to download file');
      }
    });
  }

  private convertBlobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        const base64String = (reader.result as string).split(',')[1]; // Extract the base64 part
        resolve(base64String);
      };
      reader.readAsDataURL(blob);
    });
  }
}
