export class Menu {
  constructor(
    public id: number,
    public title: string,
    public routerLink: string | null,
    public href: string | null,
    public icon: string,
    public target: string | null,
    public hasSubMenu: boolean,
    public parentId: number,
    public flagName: string,
    public role: string | null,
    public alt: string | null,
    public subMenu: boolean = false
  ) {}
}

export interface MenuItem {
  icon: string;
  label: string;
  route: string;
  externalLink?: string;
  show:boolean
}
