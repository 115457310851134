<article class="dashboard-card" [ngClass]="{'gap-3': !(tileInfo.number)}">
  <div class="card-header">
    <div class="card-title-wrapper">
      <h2 class="card-title">{{ tileInfo?.title }}</h2>
    </div>
   
  </div>
  <div class="card-icon-wrapper">
    <img [src]="tileInfo.icon" alt="" />
  </div>
  <div class="card-content">
    <p class="card-number">{{ tileInfo?.number }}</p>
    @if (!isMobileWidth  ) {
      <p class="card-description" [innerHTML]="tileInfo.description"></p>
    }
    @if (isMobileWidth ) {
      <p class="card-description" [innerHTML]="tileInfo.mobileDescription"></p>
    }
  </div>
</article>
