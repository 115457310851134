<section class="wrapper d-flex align-items-center justify-content-between flex-column gap-3 px-0" [ngClass]="{row: !isMobileWidth}">
  <div class="w-100 row d-flex align-items-center  flex-column gap-3 px-0">
    @if (isMobileWidth) {
    <p class="section-title">My USS Account Dashboard</p>
    }

    <div class="w-100 row custom-gutter g-2 g-md-3 mt-0">
      @for (item of allowedCards; track item;let idx = $index){

      <div (click)="navigateTo(item.route)" class="card-wrapper col-6 col-xxl-3 col-xl-3 col-md-6 col-lg-4 col-sm-6">
        <app-tile [tileInfo]="item"></app-tile>
      </div>

      @if ((idx === 3 && allowedCards.length > 4 && isMobileWidth)|| (
      allowedCards.length
      <=4 && isMobileWidth && allowedCards.length-1==idx) ) { <img src="assets/v2-asset/mobile-banner.png" alt="" class="col-12 img-fluid" />
      } } @if(myussFlags?.myussAssetScanningEnabled && !isMobileWidth){
      <div class="card-wrapper col-6 col-xxl-3 col-xl-3 col-md-6 col-lg-4 col-sm-6">
        <div class="find">
          <div class="search-img">
            <img src="assets/v2-asset/magnifying_glass.svg" alt="" />
          </div>
          <div class="find-card-header">
            <span>Find a Unit</span>
            <span class="material-symbols-outlined info-circle fill info-circle">
              info
            </span>
          </div>
          <span class="find-card-description">
            Search by the serial number on the asset tag
          </span>
          <button mat-flat-button class="search-btn">Search</button>
        </div>
      </div>
      } @if(!isMobileWidth){
      <div class="col-12 col-xxl-6 col-xl-6 col-md-12 col-lg-8 col-sm-12">
        <img src="assets/v2-asset/dashboard_banner.svg" alt="" class="ms-0 banner align-self-start img-fluid" />
      </div>
      }
    </div>
  </div>
  <div class="row g-3 bottom-cards px-0">
    @for(link of links; track link){
    <div class="col-12 col-xxl-3 col-xl-3 col-md-6 col-lg-4 col-sm-6">
      <app-link-card [title]="link.title" [navigationLink]="link.navigationLink" [icon]="link.icon"></app-link-card>
    </div>
    }
  </div>
</section>