import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { MenuItem } from "../menu/menu.model";
import { NgFor } from "@angular/common";

@Component({
  selector: "app-sub-menu-items",
  standalone: true,
  imports: [],
  templateUrl: "./sub-menu-items.component.html",
  styleUrl: "./sub-menu-items.component.scss",
})
export class SubMenuItemsComponent {
  @Input() items: MenuItem[] = [];

  constructor(private router: Router) {}

  navigate(item: MenuItem) {
    if (item.route) {
      this.router.navigate([item.route]);
    } else if (item.externalLink) {
      window.open(item.externalLink, "_blank");
    }
  }
}
