import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { inject, Injectable, signal } from "@angular/core";
import { Observable } from "rxjs";
import { Location } from '@angular/common';

@Injectable({
  providedIn: "root",
})
export class HelperFunctionService {
  isFooter = signal<boolean>(false);
  constructor(private breakpointObserver: BreakpointObserver) {}

  private location = inject(Location)

  space(event: any) {
    // console.log(event.target.selectionStart, event.code);
    if (
      ["Space", "Enter"].includes(event.code) &&
      event.target.selectionStart == 0
    ) {
      event.preventDefault();
    }
  }

   toCamelCase(str: string): string {
    const words = str?.split(' ');
    const camelCasedRest = words
      ?.map((word, index) => {
        return index === 0
          ? word.toLowerCase() 
          : word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join('');

    return camelCasedRest;
  }

  convertStringToObject(inputString: string): Record<string, boolean> {
    const keysArray = inputString?.split(';');
    return keysArray?.reduce((acc: Record<string, boolean>, key: string) => {
      const trimmedKey = key.trim();
      const camelCaseKey = this.toCamelCase(trimmedKey);
      acc[camelCaseKey] = true;
      return acc;
    }, {});
  }

  getMatchingTrueFields(userModules:Record<string,boolean>, accountModules:Record<string,boolean>) {
    if(!userModules || !accountModules) return {};
    let enabledModules:Record<string,boolean> = {};
  
    for (let module in userModules) {
      if (userModules.hasOwnProperty(module) && userModules[module] === true && accountModules[module] === true) {
        enabledModules[module] = true;
      }
    }
  
    return enabledModules;
  }


   // General method to detect if the viewport matches the custom media query
   isViewportMatch(query: string): Observable<BreakpointState> {
    return this.breakpointObserver.observe([query]);
  }

  removePrefix(quoteName:string) {
    return quoteName.replace('Q-', '');
  }

  formatDate(date: string | null | undefined): string {
    return date ? new Date(date).toLocaleDateString('en-US') : '';
  }

  goBack(): void {
    this.location.back(); // Navigate to the previous page
  }

  


}