@for(item of items; track item){
    @if (item.show) {

        <div class="menu-container">
          <div class="sub-menu-item" (click)="navigate(item)">
            <span class="menu-label">{{ item.label }}</span>
            <!-- <img [src]="item.icon" alt="{{ item.label }}" class="menu-icon" /> -->
          </div>
        </div>
    }
}
