<mat-drawer-container
  id="mainSection"
  class="page-wrapper"
  style="overflow: auto"
>
<!-- left side drawer -->
  <mat-drawer (openedChange)="updateToggleSignal($event)"
    #sidenav
    [opened]="settings.sidenavIsOpened"
    [mode]="settings.sidenavIsPinned ? 'side' : 'over'"
    (openedStart)="showSidenav = !showSidenav"
    (closedStart)="showSidenav = !showSidenav"
    class="sidenav mat-drawer-border-none"
  >
    <div class="logo-wrapper d-flex justify-content-center align-items-center">
      <img
        src="assets/v2-asset/logo.svg"
        *ngIf="settings.menuType == 'default'"
        class="logo-lg"
        alt="My site services logo"
      />
      <img
        src="assets/v2-asset/logo-sm.svg"
        *ngIf="settings.menuType == 'mini'"
     class="logo-sm"
        alt="My site services logo"
      />
    </div>
    <app-sidenav> </app-sidenav>
 
  </mat-drawer>

  <mat-drawer-content #mainContent class="main-content" style="overflow: auto">
    <!-- top toolbar -->
    <header class="header-toolbar-div">
      <mat-toolbar class="header-toolbar">
        @if (!isMobile) {
        <div class="ms-2">
          <span class="fs-18 font-weight-600">{{
            profileService.selectedAccount().accountName
          }}</span>
          <span class="fs-18 font-weight-600 mx-1">|</span>
          <span class="fs-18 font-weight-600">{{
            profileService.selectedAccount().accountNumber
          }}</span>
        </div>
        }@else{
        <div>
          <img
            (click)="collapse()"
            src="../../../../../../assets/v2-asset/menu-burger.svg"
            class="hamburger"
            alt="menu icon"
          />
        </div>
        }

        <span class="example-spacer"></span>
        <!-- @if (isMobile) {
        <div>
          <span class="fs-18 font-weight-600">{{ appTitle }}</span>
        </div>
        <span class="example-spacer"></span>
        } -->
   

  <div class="icons pe-2 d-flex justify-content-center align-items-center">
    @if(!isMobile){

      <img src="assets/v2-asset/help.svg" alt="help icon" />
    }
    <img src="assets/v2-asset/notification.svg" alt="notification icon" />
  </div>

        <app-user-menu></app-user-menu>
      </mat-toolbar>

      @if (!isMobile) {
      <div
        class="left-side-nav side-nav"
        [ngClass]="{ 'mini-side-nav': settings.menuType == 'mini' }"
        (click)="collapse()"
      >
      <div class="verticle_line"></div>
        <img
          [ngClass]="{ 'rotate-180': settings.menuType == 'mini' }"
          src="assets/v2-asset/open-collapse.svg"
          alt="left side menu toggle"
        />
      <div class="verticle_line"></div>
      </div>
      }
    </header>

    <div class="content-div">
      <router-outlet></router-outlet>
    </div>

    <!-- footer -->
     @if(!isNative){
       <mat-toolbar
         color="primary"
         class="footer"
         [class.full-width]="!showSidenav"
       >
         <div class="fs-12 footer-div">
           <span class="font-weight-400"> ©{{ copyrightDate }}</span>
           <span class="font-weight-400 mx-1">|</span>
           <span class="font-weight-700 footer-text">United Site Services</span>
           <span class="font-weight-400 mx-1">|</span>
           <span class="font-weight-400">All rights reserved.</span>
         </div>
       </mat-toolbar>
     }
    @if (showToggle) {
    <div
      class="toggle-side-nav side-nav"
      [ngClass]="{ opened: isNotificationMenuOpened && isLargeScreen }"
      (click)="toggle()"
    >
    <div class="verticle_line lm-5" [ngClass]="{ 'lm-5': isNotificationMenuOpened }" ></div>
      <img
        [ngClass]="{ 'rotate-180': isNotificationMenuOpened }"
        src="assets/v2-asset/open-collapse.svg"
        alt="right side menu toggle"
      />
      <div class="verticle_line" [ngClass]="{ 'lm-5': isNotificationMenuOpened }"></div>
    </div>
    }
  </mat-drawer-content>

  <!-- right side drawer -->
  <mat-drawer
    #notificationDrawer
    [mode]="isLargeScreen ? 'side' : 'over'"
    class="notification-drawer"
    position="end"
    (openedChange)="onDrawerStateChange($event)"
  >
    <div class="p-3 right-drawer-wrapper" >
      @if (showToggle) {
      <div class="toggle-side-nav-over side-nav" (click)="toggle()">
        <div class="verticle_line" [ngClass]="{ 'lm-5': isNotificationMenuOpened }"></div>
        <img
          [ngClass]="{ 'rotate-180': isNotificationMenuOpened }"
          src="assets/v2-asset/open-collapse.svg"
          alt="right side menu toggle"
        />
        <div class="verticle_line" [ngClass]="{ 'lm-5': isNotificationMenuOpened }"></div>
      </div>
      }
      <app-notifications></app-notifications>
    </div>
  </mat-drawer>
</mat-drawer-container>
